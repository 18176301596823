// Generated by Framer (461de17)

import { addFonts, cx, CycleVariantState, FormPlainTextInput, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zNbwDnbO4"];

const serializationHash = "framer-y6nPB"

const variantClassNames = {zNbwDnbO4: "framer-v-1lriolg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zNbwDnbO4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.label {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1lriolg", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zNbwDnbO4"} ref={ref ?? ref1} style={{...style}}><FormPlainTextInput className={"framer-xcv46v"} inputName={"phone-number"} layoutDependency={layoutDependency} layoutId={"U8KbrRieR"} placeholder={"Entrez votre Téléphone "} required style={{"--framer-input-background": "rgba(187, 187, 187, 0.15)", "--framer-input-border-bottom-width": "1px", "--framer-input-border-color": "rgba(136, 136, 136, 0.1)", "--framer-input-border-left-width": "1px", "--framer-input-border-radius-bottom-left": "10px", "--framer-input-border-radius-bottom-right": "10px", "--framer-input-border-radius-top-left": "10px", "--framer-input-border-radius-top-right": "10px", "--framer-input-border-right-width": "1px", "--framer-input-border-style": "solid", "--framer-input-border-top-width": "1px", "--framer-input-font-color": "rgb(153, 153, 153)", "--framer-input-icon-color": "rgb(153, 153, 153)", "--framer-input-placeholder-color": "rgb(153, 153, 153)"}} type={"tel"}/></motion.label></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-y6nPB.framer-1qs6utk, .framer-y6nPB .framer-1qs6utk { display: block; }", ".framer-y6nPB.framer-1lriolg { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; padding: 0px; position: relative; width: 507px; }", ".framer-y6nPB .framer-xcv46v { --framer-input-focused-border-color: #0099ff; --framer-input-focused-border-style: solid; --framer-input-focused-border-width: 1px; --framer-input-font-family: \"Inter\"; --framer-input-font-letter-spacing: 0em; --framer-input-font-line-height: 1.2em; --framer-input-font-size: 14px; --framer-input-font-weight: 400px; --framer-input-padding: 12px; flex: none; height: 40px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-y6nPB.framer-1lriolg { gap: 0px; } .framer-y6nPB.framer-1lriolg > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-y6nPB.framer-1lriolg > :first-child { margin-top: 0px; } .framer-y6nPB.framer-1lriolg > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 507
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerk1OCJDCgY: React.ComponentType<Props> = withCSS(Component, css, "framer-y6nPB") as typeof Component;
export default Framerk1OCJDCgY;

Framerk1OCJDCgY.displayName = "note-app";

Framerk1OCJDCgY.defaultProps = {height: 40, width: 507};

addFonts(Framerk1OCJDCgY, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZ1rib2Bg-4.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})